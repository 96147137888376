import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import serverPath from "../serverPath";

const clearStorage = () => {
  // Clear cookies
  Cookies.remove("candidate_token");

  // Clear local storage
  localStorage.clear();
};
const getAccessToken = () => {
  return Cookies.get("candidate_token");
};
const isTokenExpired = async () => {
  const access_token = Cookies.get("candidate_token");

  if (access_token) {
    const decodedToken = jwtDecode(access_token);

    const currentTime = Date.now() / 1000;
    const OrganizationId = Cookies.get("OrganizationId");
    if (decodedToken.exp < currentTime) {
      clearStorage();
      Cookies.remove("candidate_token");
      localStorage.clear();
      window.location = `/?key=${OrganizationId}`;
    }
  }
};
export const candidate_test = axios.create({
  baseURL: serverPath.CANDIDATE_OTP,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
export const organisation = axios.create({
  baseURL: serverPath.ORGANISATION,
  headers: {
    "Content-Type": "application/json",
  },
});

candidate_test.interceptors.request.use(async (config) => {
  await isTokenExpired();
  const access_token = getAccessToken();
  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token}`;
  }
  return config;
});
export default isTokenExpired;
