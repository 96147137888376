import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { candidate_test } from "../helper/axios-instance/CanddiateQuestion";
import Cookies from "js-cookie";
import * as faceapi from "face-api.js"; // Import face-api.js

toast.configure({
  autoClose: 2000,
  pauseOnFocusLoss: false,
});

const mimeType = 'video/webm;';

const VideoRecorder = ({
  selectedAnswer,
  setSelectedAnswer,
  questionIndex,
  data,
  permission,
  setPermission,
  UpdateLocalStorage,
  videoStopped,
  setVideoStopped,
}) => {
  const [recordedVideo, setRecordedVideo] = useState(selectedAnswer[questionIndex] || null);
  const [seconds, setSeconds] = useState(data?.answer_duration * 60);
  const videoRef = useRef(null);
  const mediaRecorder = useRef(null);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoStarted, setVideoStarted] = useState(false);
  const [questionData, setQuestionData] = useState();
  const [faceDetected, setFaceDetected] = useState(true); // State to track face detection

  const openDatabase = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("examDB", 1);
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("videos")) {
          db.createObjectStore("videos", { keyPath: "id" });
        }
      };
      request.onerror = (event) => reject("Database failed to open");
      request.onsuccess = (event) => resolve(event.target.result);
    });
  };

  const saveVideoToDB = (videoData, videoId) => {
    openDatabase().then((db) => {
      const transaction = db.transaction("videos", "readwrite");
      const store = transaction.objectStore("videos");
      const videoObj = { id: videoId, videoData };
      store.put(videoObj);
    }).catch((error) => {
      console.error("Error saving video to DB", error);
    });
  };

  const getVideoFromDB = (videoId) => {
    return new Promise((resolve, reject) => {
      openDatabase().then((db) => {
        const transaction = db.transaction("videos", "readonly");
        const store = transaction.objectStore("videos");
        const request = store.get(videoId);
        request.onsuccess = (event) => resolve(event.target.result?.videoData || null);
        request.onerror = (event) => reject("Error retrieving video from DB");
      }).catch((error) => {
        console.error("Error accessing DB", error);
        reject("Database error");
      });
    });
  };

  // Load face-api model with tinyFaceDetector
  const loadModel = async () => {
    await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
  };

  useEffect(() => {
    loadModel();
  }, []);

  const startRecording = async () => {
    setVideoStopped(false);
    setVideoStarted(true);
    setRecordedVideo(null);
    setFaceDetected(true); // Reset face detection status on start

    if (!("MediaRecorder" in window)) {
      return alert("The MediaRecorder API is not supported in your browser.");
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      setPermission(true);
      videoRef.current.srcObject = stream;
      mediaRecorder.current = new MediaRecorder(stream, { mimeType });
      mediaRecorder.current.start();
      const localVideoChunks = [];
      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) localVideoChunks.push(event.data);
        var reader = new FileReader();
        reader.readAsDataURL(event.data);
        reader.onloadend = function () {
          var base64data = reader.result;
          // Remove codec information from the data URI
          var strippedBase64Data = base64data.replace(/;codecs=[^;]+/g, "");

          setRecordedVideo(strippedBase64Data);
          saveVideoToDB(strippedBase64Data, questionIndex); // Save to IndexedDB
          setSelectedAnswer((prev) => {
            const updatedAnswers = [...prev];
            updatedAnswers[questionIndex] = strippedBase64Data;
            return updatedAnswers;
          });
        };
      };

      mediaRecorder.current.onstop = () => {
        const videoBlob = new Blob(localVideoChunks, { type: mimeType });
        const videoUrl = URL.createObjectURL(videoBlob);
      };

      // Start the face detection loop using tinyFaceDetector
      const detectFace = async () => {
        const detections = await faceapi.detectSingleFace(videoRef.current, new faceapi.TinyFaceDetectorOptions());
        console.log("detection", detections);
        if (!detections) {
          setFaceDetected(false); // No face detected
          toast.error("No face detected! Please stay in the frame.", {
            style: {
              width: '500px',   
              marginRight: '30px', 
            },
          });
        } else {
          setFaceDetected(true);
        }
      };

      // Check for face presence during recording
      const faceDetectionInterval = setInterval(detectFace, 500);

      // Stop face detection when the recording is stopped
      mediaRecorder.current.onstop = () => {
        clearInterval(faceDetectionInterval);
      };

    } catch (error) {
      toast.error("Please give access to camera and audio to start the test");
      console.error(error);
    }
  };

  const stopRecording = () => {
    setVideoStopped(true);
    setVideoStarted(false);
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setPermission(false);
      setSeconds(data?.answer_duration * 60);
    } else {
      console.warn("MediaRecorder is not initialized or has already been stopped.");
    }
  };

  const resetRecording = () => {
    setRecordedVideo(null);
    setPermission(false);
    videoRef.current.srcObject = null;
    videoRef.current.src = "";
    setSeconds(data?.answer_duration * 60);
    setSelectedAnswer((prev) => {
      const updatedAnswers = [...prev];
      updatedAnswers[questionIndex] = null;
      return updatedAnswers;
    });
  };

  useEffect(() => {
    videoRef.current.srcObject = null;
    getVideoFromDB(questionIndex).then((videoData) => {
      if (videoData) {
        setRecordedVideo(videoData);
        videoRef.current.src = videoData;
      }
    }).catch(() => {
      console.log("No video found in DB for this question.");
    });

    if (recordedVideo) {
      videoRef.current.src = recordedVideo;
    }
  }, [recordedVideo, questionIndex]);

  useEffect(() => {
    if (permission) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          stopRecording();
        }
      }, 1000);

      return () => {
        clearInterval(myInterval);
      };
    }
  }, [permission, seconds]);

  return (
    <div className="d-flex flex-column gap-2">
      <div style={{ width: "100%", maxWidth: "450px", margin: "0" }}>
        <video
          ref={videoRef}
          autoPlay={!recordedVideo}
          muted
          controls={!permission}
          style={{
            width: "100%",
            height: "auto",
            display: selectedAnswer[questionIndex] || permission ? "block" : "none",
          }}
          className="mt-3"
        >
        </video>
      </div>

      {!(selectedAnswer[questionIndex] || permission) && (
        <>
          <div className="card mb-3 border-0">
            <div className="card-body p-0">
              <div className="card-title mb-3">
                <h5 className="mb-1">This is a video question.</h5>
                <h5 className="mb-0">Please click on start to submit your video answer.</h5>
              </div>
              <h6 className="card-subtitle">Question will be shared after starting the answer.</h6>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center" id="buttonId" style={{ height: "200px", backgroundColor: "#000", borderRadius: "5px" }}>
            <div className="card border-0 bg-dark">
              <div className="card-body p-0">
                <button
                  onClick={startRecording}
                  type="button"
                  className="btn btn-primary"
                  id="customButton"
                  disabled={permission || selectedAnswer[questionIndex]}
                >
                  Start Recording
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {videoStarted && !recordedVideo && (
        <button
          type="button"
          className="btn btn-primary mt-2"
          id="customButton"
          onClick={stopRecording}
          disabled={!permission}
          style={{ width: "150px" }}
        >
          Stop Recording
        </button>
      )}

      {!recordedVideo && (
        <div className="question-card-tools mt-4">
          <p>Time remaining: {permission && seconds ? `${seconds} sec` : `${data?.answer_duration * 60} sec`}</p>
        </div>
      )}
    </div>
  );
};

export default VideoRecorder;
